const initialState = {
  msg: {},
  status: null,
  id: null,
  type: null,
  errorHistory: [],
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_ERRORS":
      return {
        msg: action.payload.msg,
        status: action.payload.status,
        id: action.payload.id,
        type: action.payload.type,
        errorHistory: [...state.errorHistory, action.payload],
      };
    case "CLEAR_ERRORS":
      return {
        msg: {},
        status: null,
        id: null,
        type: null,
        errorHistory: [...state.errorHistory],
      };
    default:
      return state;
  }
};
