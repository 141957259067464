import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import NavLink from "./NavLink";

import Blob1 from "../assets/images/blob1.svg";
import Blob2 from "../assets/images/blob2.svg";

const NavMenu = ({ logoutUser }) => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation().pathname;
  const user = useSelector((state) => state.user.user);
  const active = useSelector((state) => state.ui.menuVisible);
  const { instance } = useMsal();

  useEffect(() => {
    if (active) {
      window.scrollTo(0, 0);
    }
  }, [active]);

  const handleLogout = () => {
    logoutUser(instance);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {user.role && isAuthenticated ? (
        <nav className="nav-menu">
          <div className={`nav-menu-container ${active ? "visible" : "hidden"}`}>
            <div className="nav-menu-items">
              <NavLink text="Home" to="" active={location === "/"} />
              {user.role === "Admin" ? (
                <NavLink text="Admin" to="admin" active={location === "/admin"} />
              ) : null}
              <NavLink text="Profile" to="profile" active={location === "/profile"} />
              <NavLink
                text="Log Out"
                to="/login"
                active={location === "/login"}
                clickAction={handleLogout}
              />
            </div>
          </div>
        </nav>
      ) : null}
      {active && (
        <>
          <img alt="" className="backgroundImage" id="blob1_navmenu" src={Blob1} />
          <img alt="" className="backgroundImage" id="blob2_navmenu" src={Blob2} />
        </>
      )}
    </>
  );
};

// Prop types
NavMenu.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

export default NavMenu;
