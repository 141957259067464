import api from "../api";
import { returnErrors } from "./errorActions";

export const loadUser = (userData, accessToken) => (dispatch) => {
  dispatch({ type: "USER_LOADING" });

  const { roles, accountId, username } = userData;
  let role = process.env.NODE_ENV === "development" ? "Admin" : "Normal";

  // Check if user is admin
  if (
    process.env.REACT_APP_DEPLOY_ENVIRONMENT === "staging"
      ? roles.includes("Staging_Admin") || roles.includes("Admin")
      : roles.includes("Admin")
  ) {
    role = "Admin";
  }

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  api
    .get(`/users/${accountId}`, config)
    .then((res) => {
      const { ssn, directoryName } = res.data;

      const userObj = {
        ssn,
        directoryName,
        role,
        accountId,
        username,
      };
      dispatch({
        type: "LOAD_USER",
        payload: {
          ...userObj,
        },
      });
    })
    .catch((err) => {
      if (!err.response) {
        dispatch(returnErrors(err.message, err.statusCode, "error", "LOAD_USER_ERROR"));
      } else {
        // it will soon
        if (err.response.data.msg === "User doesn't exist") return;
        dispatch(
          returnErrors(err.response.data.msg, err.response.status, "error", "LOAD_USER_ERROR")
        );
      }
      dispatch({
        type: "LOAD_USER_ERROR",
      });
    });
};

export const getUserSSN = (_accountId, accessToken) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  api
    .get("/users/ssn", config)
    .then((res) => {
      dispatch({ type: "LOAD_SSN", payload: res.data.ssn });
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return;
      }
      if (!err.response) {
        dispatch(returnErrors(err.message, err.statusCode, "error", "LOAD_SSN_ERROR"));
      } else {
        dispatch(
          returnErrors(err.response.data.msg, err.response.status, "error", "LOAD_SSN_ERROR")
        );
      }
      dispatch({
        type: "LOAD_SSN_ERROR",
      });
    });
};

export const saveUser = (userData, accessToken) => (dispatch) => {
  dispatch({ type: "SAVE_USER_LOADING" });

  const { roles, username } = userData;
  let role = "Normal";

  // Check if user is admin
  if (roles.includes("Admin")) {
    role = "Admin";
  }

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  api
    .post("/users", undefined, config)
    .then((res) => {
      // eslint-disable-next-line no-shadow
      const { ssn, accountId, directoryName } = res.data;
      dispatch({
        type: "SAVE_USER",
        payload: {
          accountId,
          ssn,
          role,
          username,
          directoryName,
        },
      });
    })
    .catch((err) => {
      if (!err.response) {
        dispatch(returnErrors(err.message, err.statusCode, "error", "SAVE_USER_ERROR"));
      } else {
        dispatch(
          returnErrors(err.response.data.msg, err.response.status, "error", "SAVE_USER_ERROR")
        );
      }
      dispatch({
        type: "SAVE_USER_ERROR",
      });
    });
};

export const updateUser = (updateValues, _id, accessToken) => (dispatch) => {
  dispatch({ type: "UPDATE_USER_LOADING" });

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  api
    .put("/users", updateValues, config)
    .then((res) => {
      dispatch(returnErrors(res.data.msg, 200, "success", "UPDATE_USER"));
      dispatch({
        type: "UPDATE_USER",
        payload: res.data.newValues,
      });
    })
    .catch((err) => {
      if (!err.response) {
        dispatch(returnErrors(err.message, err.statusCode, "error", "UPDATE_USER_ERROR"));
      } else {
        dispatch(
          returnErrors(err.response.data.msg, err.response.status, "error", "UPDATE_USER_ERROR")
        );
      }
      dispatch({
        type: "UPDATE_USER_ERROR",
      });
    });
};

export const deleteUserSSN = (_id, accessToken) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  api
    .delete(`/users/ssn`, config)
    .then((res) => {
      dispatch(returnErrors(res.data.msg, 200, "success", "DELETE_USER_SSN"));
      dispatch({ type: "DELETE_USER_SSN" });
    })
    .catch((err) => {
      if (!err.response) {
        dispatch(returnErrors(err.message, err.statusCode, "error", "DELETE_USER_SSN_ERROR"));
      } else {
        dispatch(
          returnErrors(err.response.data.msg, err.response.status, "error", "UPDATE_USER_SSN_ERROR")
        );
      }
      dispatch({
        type: "UPDATE_USER_SSN_ERROR",
      });
    });
};

export const getUserById = (accountId, accessToken) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  api
    .get(`/users/${accountId}`, config)
    .then((res) => {
      const userObj = {
        ssn: res.data.ssn || "",
        accountId,
        username: res.data.username,
      };
      dispatch({
        type: "GET_NOTIFICATION_CREATOR",
        payload: {
          ...userObj,
        },
      });
    })
    .catch((err) => {
      if (!err.response) {
        dispatch(returnErrors(err.message, err.statusCode, "error", "LOAD_USER_ERROR"));
      } else {
        dispatch(
          returnErrors(err.response.data.msg, err.response.status, "error", "LOAD_USER_ERROR")
        );
      }
      dispatch({
        type: "LOAD_USER_ERROR",
      });
    });
};
