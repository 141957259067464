/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { menuVisible } from "../actions/uiActions";

const MenuBtn = () => {
  const dispatch = useDispatch();
  const visible = useSelector((state) => state.ui.menuVisible);

  const handleClick = () => {
    dispatch(menuVisible(!visible));
  };

  return (
    <div className="hamburger-wrapper">
      <div
        role="button"
        tabIndex={0}
        onClick={handleClick}
        className={`hamburger ${visible ? "open" : "closed"}`}
      >
        <span className="line-1" />
        <span className="line-2" />
        <span className="line-3" />
      </div>
    </div>
  );
};

export default MenuBtn;
