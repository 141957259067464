import React from "react";
import PropTypes from "prop-types";

import "../styles/Loader.css";

const Loader = ({ loaderSize }) => (
  <div className={`loader ${loaderSize === "fullscreen" ? "fullscreen" : "small"}`}>
    <div className="lds-ellipsis">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

// Prop types
Loader.propTypes = {
  loaderSize: PropTypes.string,
};

Loader.defaultProps = {
  loaderSize: "fullscreen",
};

export default Loader;
