const initialState = {
  eventsById: [],
  notificationById: {},
  newNotification: {},
  activeNotificationInModal: {},
  filteredEvents: [], // used for filtered notifications list
  loading: false,
  fullDayModeActive: true,
  partTimeData: { start: "", end: "" },
  overlappingEventTypes: [],
  latestActiveMonth: "",
};

// eslint-disable-next-line default-param-last
export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_LOADING":
    case "UPDATE_LOADING":
    case "NOTIFICATIONS_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "GET_NOTIFICATION":
      return {
        ...state,
        notificationById: action.payload,
        loading: false,
      };

    case "UPDATE_OVERLAPPING_EVENTS":
      return {
        ...state,
        overlappingEventTypes: action.payload,
      };

    case "ADD_NOTIFICATION":
    case "UPDATE_NOTIFICATION_SUCCESS":
    case "ADD_NOTIFICATION_ERROR":
    case "UPDATE_NOTIFICATION_ERROR":
    case "NORMAL_NOTIFICATION_ERROR":
    case "ADMIN_NOTIFICATION_ERROR":
      return {
        ...state,
        loading: false,
      };

    case "LOAD_NOTIFICATIONS-TO-STATE":
    case "LOAD_ADMIN_NOTIFICATIONS-TO-STATE":
      return {
        ...state,
        eventsById: action.payload,
        filteredEvents: [],
        loading: false,
      };

    case "INITIATE_NEW-NOTIFICATION": // used to populate createNewNotificationModal
      sessionStorage.setItem("scrollPosition", window.pageYOffset);
      return {
        ...state,
        newNotification: {
          ...action.payload,
          tempStart: action.payload.tempStart ? action.payload.tempStart : action.payload.start,
          tempEnd: action.payload.tempEnd ? action.payload.tempEnd : action.payload.end,
        },
        fullDayModeActive: 1,
        latestActiveMonth: action.payload.startStr || state.latestActiveMonth,
      };

    case "INITIATE_OLD-NOTIFICATION": // used to populate createNewNotificationModal
      return {
        ...state,
        newNotification: {
          ...action.payload,
        },
      };

    case "FETCH_NOTIFICATION-POPULATE-MODAL": // used to populate createNewNotificationModal with id
      return {
        ...state,
        activeNotificationInModal: state.eventsById.filter((obj) => obj.id === action.payload.id),
      };

    case "ADD_NEW-NOTIFICATION":
      return {
        ...state,
        eventsById: [
          ...state.eventsById,
          {
            id: action.payload.id,
            creatorId: action.payload.creatorId,
            start: state.partTimeData.start ? state.partTimeData.start : action.payload.start,
            end: state.partTimeData.end ? state.partTimeData.end : action.payload.end,
            startStr: action.payload.startStr,
            endStr: action.payload.endStr,
            type: action.payload.type,
            comment: action.payload.comment,
            fullDayModeActive: action.payload.fullDayModeActive,
          },
        ],
        loading: false,
      };

    case "ADD_FILE-TO-NOTIFICATION":
      return {
        ...state,
        eventsById: state.eventsById.map((event) =>
          event.id === action.payload.id
            ? { ...event, files: [...event.files, action.payload.file] }
            : event
        ),
        loading: false,
      };

    case "CREATE_FILTERED-NOTIFICATION-LIST":
      return {
        ...state,
        filteredEvents: action.payload,
        loading: false,
      };

    case "CHANGE_NOTIFICATION-FULL-DAY-MODE":
      return {
        ...state,
        fullDayModeActive: action.payload,
      };

    case "SAVE_NOTIFICATION-PART-TIME-DATA":
      return {
        ...state,
        partTimeData: {
          start: action.payload.start ? action.payload.start : state.partTimeData.start,
          end: action.payload.end ? action.payload.end : state.partTimeData.end,
        },
      };

    case "RESET_NOTIFICATION-PART-TIME-DATA":
      return {
        ...state,
        partTimeData: {
          start: null,
          end: null,
        },
      };
    case "UPDATE_NOTIFICATION_MOVE":
      return {
        ...state,
        eventsById: state.eventsById.map((notif) =>
          notif.id === action.payload.id ? { ...notif, ...action.payload.data } : notif
        ),
      };
    default:
      return state;
  }
}
