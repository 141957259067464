import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { bool, string, any } from "prop-types";
import { Link } from "react-router-dom";

import { menuVisible } from "../actions/uiActions";

const NavLink = ({ text, to, active, clickAction = false }) => {
  const dispatch = useDispatch();
  const [isHover, setIsHover] = useState(false);

  const handleClick = () => {
    dispatch(menuVisible(false));
  };

  return (
    <Link
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={`nav-item ${isHover ? "hover" : "not-hover"} ${active ? "active" : "inactive"} ${
        to || "home"
      }-link`}
      to={`/${to}`}
      onClick={clickAction || handleClick}
    >
      <span className="nav-item-text">{text}</span>
      <div className="underline" />
    </Link>
  );
};

NavLink.propTypes = {
  text: string.isRequired,
  to: string.isRequired,
  active: bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  clickAction: any,
};

NavLink.defaultProps = {
  clickAction: false,
};

export default NavLink;
