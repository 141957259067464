import React from "react";
import { func } from "prop-types";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import NavLink from "./NavLink";

import "../styles/Navbar.css";

const Navbar = ({ logoutUser }) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const user = useSelector((state) => state.user.user);
  const location = useLocation().pathname;

  const handleLogout = () => {
    logoutUser(instance);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {user.role && isAuthenticated ? (
        <nav className="navbar center">
          <div className="navbar-container">
            <NavLink text="Home" to="" active={location === "/"} />
            {user.role === "Admin" ? (
              <NavLink text="Admin" to="admin" active={location === "/admin"} />
            ) : null}
            <NavLink text="Profile" to="profile" active={location === "/profile"} />
            <NavLink
              text="Log Out"
              to="login"
              active={location === "/login"}
              clickAction={handleLogout}
            />
          </div>
        </nav>
      ) : null}
    </>
  );
};

Navbar.propTypes = {
  logoutUser: func,
};

Navbar.defaultProps = {
  logoutUser: func,
};

export default Navbar;
