const initialState = {
  weekendsVisible: true,
  createNotificationModalVisible: false,
  notificationsTableVisible: true,
  logsTableVisible: false,
  sickLeaveReportingComponentVisibile: true,
  drugTestReportingComponentVisibile: false,
  showListFilter: false,
  activeStartDate: "",
  activeEndDate: "",
  dialogVisible: false,
  dialogResult: null,
  menuVisible: false,
  theme: "",
  dialog: {
    title: "Confirm",
    text: "Are you sure?",
    yes: "Yes",
    no: "No",
    id: "default",
  },
};

// eslint-disable-next-line default-param-last
export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_WEEKENDS":
      return {
        ...state,
        weekendsVisible: !state.weekendsVisible,
      };

    case "TOGGLE_CREATE-NOTIFICATION-MODAL":
      return {
        ...state,
        createNotificationModalVisible: !state.createNotificationModalVisible,
      };

    case "SHOW-NOTIFICATIONS-TABLE":
      return {
        ...state,
        logsTableVisible: false,
        notificationsTableVisible: true,
      };

    case "SHOW-LOGS-TABLE":
      return {
        ...state,
        notificationsTableVisible: false,
        logsTableVisible: true,
      };

    case "SHOW_SICK-LEAVE-REPORTING":
      return {
        ...state,
        sickLeaveReportingComponentVisibile: true,
        drugTestReportingComponentVisibile: false,
      };

    case "SHOW_DRUG-TEST-REPORTING":
      return {
        ...state,
        sickLeaveReportingComponentVisibile: false,
        drugTestReportingComponentVisibile: true,
      };

    case "TOGGLE-LIST-FILTER":
      return {
        ...state,
        showListFilter: !state.showListFilter,
      };
    case "SET_ACTIVE_MONTH":
      return {
        ...state,
        activeStartDate: action.payload.activeStartDate,
        activeEndDate: action.payload.activeEndDate,
      };
    case "SHOW_DIALOG":
      return {
        ...state,
        dialogVisible: true,
        dialog: {
          ...state.dialog,
          ...action.payload,
        },
      };
    case "DIALOG_RESULT":
      return {
        ...state,
        dialogResult: action.payload,
        dialogVisible: false,
      };
    case "RESET_DIALOG":
      return {
        ...state,
        dialogResult: null,
        dialog: {
          ...state.dialog,
          title: "Confirm",
          text: "Are you sure?",
          yes: "Yes",
          no: "No",
        },
      };
    case "SET_MENU_VISIBLE":
      return {
        ...state,
        menuVisible: action.payload,
      };
    case "SET_THEME":
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return state;
  }
}
