/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clearErrors } from "../actions/errorActions";

import Close from "../assets/images/close.svg";
import "../styles/Toast.css";

const Toast = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.errors);
  const [visible, setVisible] = useState(null);
  const [type, setType] = useState(null);
  const [text, setText] = useState(null);
  const [hover, setHover] = useState(null);

  useEffect(() => {
    const disappear = setTimeout(() => {
      setVisible(false);
      dispatch(clearErrors());
    }, 1500);
    return () => clearTimeout(disappear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (error.id) {
      setVisible(true);
      setType(error.type);
      setText(error.msg);
      if (!hover) {
        const disappear = setTimeout(() => {
          setVisible(false);
          dispatch(clearErrors());
        }, 2750);
        return () => clearTimeout(disappear);
      }
    }
    return null;
  }, [dispatch, error, hover]);

  const closeToast = () => {
    setVisible(false);
    dispatch(clearErrors());
  };

  return (
    <div
      className={`toast ${type} ${visible ? "show" : "hide"}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="toast-container">
        <div className="toast-content">
          <div className="toast-body">
            <p className="toast-text">{text}</p>
          </div>
          <div className="toast-close">
            <img onClick={closeToast} alt="" src={Close} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toast;
