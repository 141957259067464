import { combineReducers } from "redux";

import logsReducer from "./logsReducer";
import notificationsReducer from "./notificationReducer";
import filesReducer from "./filesReducer";
import uiReducer from "./uiReducer";
import userReducer from "./userReducer";
import errorReducer from "./errorReducer";

const rootReducer = combineReducers({
  notifications: notificationsReducer,
  logs: logsReducer,
  ui: uiReducer,
  user: userReducer,
  files: filesReducer,
  errors: errorReducer,
});

export default rootReducer;
