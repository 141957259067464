const initialState = {
  isLogged: false,
  user: {},
  adminModeActive: null,
  loading: false,
  notificationCreator: "",
};

// eslint-disable-next-line default-param-last
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_USER_LOADING":
    case "SAVE_USER_LOADING":
    case "USER_LOADING":
    case "LOGIN_IN_PROGRESS":
      return {
        ...state,
        loading: true,
      };

    case "LOAD_SSN":
      return {
        ...state,
        user: { ...state.user, ssn: action.payload },
      };

    case "UPDATE_USER":
    case "UPDATE_USER_ERROR":
    case "LOAD_USER_ERROR":
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.payload },
      };
    case "DELETE_USER_SSN":
      return {
        ...state,
        user: { ...state.user, ssn: "" },
      };
    case "TOGGLE_ADMIN-MODE-ON":
      return {
        ...state,
        adminModeActive: true,
      };

    case "TOGGLE_ADMIN-MODE-OFF":
      return {
        ...state,
        adminModeActive: false,
      };

    case "GET_USER":
      return {
        ...state,
        isLogged: false,
        user: action.payload,
        loading: false,
      };

    case "LOAD_USER":
    case "SAVE_USER":
      return {
        ...state,
        isLogged: true,
        user: action.payload,
        loading: false,
      };

    case "GET_NOTIFICATION_CREATOR":
      return {
        ...state,
        notificationCreator: action.payload.username,
      };

    case "USER_ERROR":
    case "LOGOUT":
    case "SAVE_USER_ERROR":
      return {
        ...state,
        isLogged: false,
        user: {},
        loading: false,
      };

    default:
      return state;
  }
}
