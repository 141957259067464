import { useState } from "react";
import { loginRequest, graphRequest } from "../authConfig";

const useAccessTokens = (accounts, instance) => {
  const [accessToken, setAccessToken] = useState(null);
  const [graphAccessToken, setGraphAccessToken] = useState(null);

  if (window.Cypress)
    return {
      accessToken: sessionStorage.getItem("accessToken"),
      graphAccessToken: sessionStorage.getItem("graphAccessToken"),
    };
  if (!accounts) return { accessToken, graphAccessToken };

  const account = accounts[0];

  const requests = [
    ["access", loginRequest],
    ["graphAccess", graphRequest],
  ];

  // Silently acquires an access token
  requests.forEach(([type, request]) => {
    instance
      .acquireTokenSilent({
        ...request,
        account,
      })
      .then((response) => {
        switch (type) {
          case "access":
            setAccessToken(response.accessToken);
            break;
          case "graphAccess":
            setGraphAccessToken(response.accessToken);
            break;
          default:
        }
      })
      .catch((e) => {
        if (e.errorCode === "no_account_error") {
          // eslint-disable-next-line no-console
          if (type === "access") console.log("Not logged in");
        } else {
          // eslint-disable-next-line no-console
          console.log(e);
          instance
            .acquireTokenPopup({
              ...request,
              account,
            })
            .then((response) => {
              switch (type) {
                case "access":
                  setAccessToken(response.accessToken);
                  break;
                case "graphAccess":
                  setGraphAccessToken(response.accessToken);
                  break;
                default:
              }
            });
        }
      });
  });

  return { accessToken, graphAccessToken };
};

export default useAccessTokens;
