import React, { useEffect } from "react";

import "../styles/ConfirmDialog.css";

import { useDispatch, useSelector } from "react-redux";

const ConfirmDialog = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.ui.dialog);
  const visible = useSelector((state) => state.ui.dialogVisible);

  const handleClick = (confirm) => {
    dispatch({ type: "DIALOG_RESULT", payload: confirm });
  };

  useEffect(() => {
    if (visible) {
      window.scrollTo(0, 0);
    }
  }, [visible]);

  return (
    <div className={`overlay ${visible ? "show" : "hide"}`}>
      <div className={`confirm-dialog ${visible ? "show" : "hide"}`}>
        <div className="confirm-dialog-container">
          <div className="confirm-dialog-body">
            <div className="confirm-dialog-content">
              <h2 className="confirm-title heading">{dialog.title}</h2>
              <p className="confirm-text">{dialog.text}</p>
            </div>
            <div className="confirm-dialog-options">
              <button
                type="button"
                onClick={() => handleClick(true)}
                className="confirm-btn confirm-yes-btn"
              >
                {dialog.yes}
              </button>
              <button
                type="button"
                onClick={() => handleClick(false)}
                className="confirm-btn confirm-no-btn"
              >
                {dialog.no}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
