const initialState = {
  logs: [],
  pagination: {
    currPage: 1,
    totalPages: 0,
    totalRows: 0,
    rowsPerPage: 10,
  },
  loading: false,
};

// eslint-disable-next-line default-param-last
export default function logsReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGS_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "LOAD_LOGS-TO-STATE":
      return {
        ...state,
        logs: action.payload.rows,
        pagination: action.payload.pagination,
        loading: false,
      };

    case "CREATE_LOG":
      return {
        ...state,
        logs: [...state.logs, action.payload],
        loading: false,
      };

    case "CREATE_LOG_ERROR":
    case "NORMAL_LOGS_ERROR":
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
