/* eslint-disable react/jsx-no-useless-fragment */
import React, { Suspense, lazy, useEffect } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";

import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";

import Header from "./components/Header";
import ConfirmDialog from "./components/ConfirmDialog";
import Loader from "./components/Loader";

import "./styles/main.css";
import { getTheme, setTheme } from "./actions/uiActions";
import ErrorBoundary from "./components/ErrorBoundary";

const LoginPage = lazy(() => import("./views/LoginPage"));
const FrontPage = lazy(() => import("./views/FrontPage"));
const ProfilePage = lazy(() => import("./views/ProfilePage"));
const AdminPage = lazy(() => import("./views/AdminPage"));

const AppRoutes = () => {
  const { inProgress } = useMsal();
  const isAuth = useIsAuthenticated();
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setTheme(getTheme()));
  }, [dispatch]);

  useEffect(() => {
    if (inProgress === "none" && loading !== null && !isAuth) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress, loading, isAuth]);

  return (
    <>
      {inProgress === "none" && loading !== null && (
        <>
          <ConfirmDialog />
          <Header />
          <Suspense fallback={<Loader fullscreen />}>
            <Routes>
              <Route path="/" element={<FrontPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/admin" element={<AdminPage />} />
            </Routes>
          </Suspense>
        </>
      )}
    </>
  );
};

const App = ({ msalInstance }) => (
  <MsalProvider instance={msalInstance}>
    <Router>
      <ErrorBoundary>
        <AppRoutes />
      </ErrorBoundary>
    </Router>
  </MsalProvider>
);

// Prop types
App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  msalInstance: PropTypes.any.isRequired,
};

export default App;
