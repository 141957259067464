export const toggleWeekends = () => ({
  type: "TOGGLE_WEEKENDS",
});

export const toggleIsLogged = () => ({
  type: "TOGGLE_IS-LOGGED",
});

export const toggleListFilter = () => ({
  type: "TOGGLE-LIST-FILTER",
});

export const showSickLeaveReporting = () => ({
  type: "SHOW_SICK-LEAVE-REPORTING",
});

export const showDrugTestReporting = () => ({
  type: "SHOW_DRUG-TEST-REPORTING",
});

export const toggleCreateNotificationModal = () => ({
  type: "TOGGLE_CREATE-NOTIFICATION-MODAL",
});

export const toggleAdminModeOn = () => (dispatch) => {
  dispatch({
    type: "TOGGLE_ADMIN-MODE-ON",
  });
};

export const toggleAdminModeOff = () => (dispatch) => {
  dispatch({
    type: "TOGGLE_ADMIN-MODE-OFF",
  });
};

export const showNotificationsTable = () => ({
  type: "SHOW-NOTIFICATIONS-TABLE",
});

export const showLogsTable = () => ({
  type: "SHOW-LOGS-TABLE",
});

export const setActiveMonth = (activeStartDate, activeEndDate) => (dispatch) => {
  dispatch({
    type: "SET_ACTIVE_MONTH",
    payload: { activeStartDate, activeEndDate },
  });
};

export const showDialog = (dialog) => (dispatch) => {
  dispatch({
    type: "SHOW_DIALOG",
    payload: dialog,
  });
};

export const menuVisible = (visible) => (dispatch) => {
  dispatch({
    type: "SET_MENU_VISIBLE",
    payload: visible,
  });
};

export const getTheme = () => {
  if (localStorage.getItem("theme")) return localStorage.getItem("theme");
  return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
};

export const setTheme =
  (theme, save = false) =>
  (dispatch) => {
    dispatch({
      type: "SET_THEME",
      payload: theme,
    });
    if (save) localStorage.setItem("theme", theme);
    document.documentElement.setAttribute("data-theme", theme);
  };
