import { func, string } from "prop-types";
import React from "react";
import Header from "../components/Header";

import "../styles/ErrorPage.css";

const ErrorPage = ({ title = "Something went wrong", msg, handleRefresh }) => {
  return (
    <div className="error-page page">
      <Header minimal />
      <div className="error-page-content page-content">
        <h1 className="">{title}</h1>
        <p className="error-page-error-msg">{msg}</p>
        <p className="error-page-msg">
          If this error reoccurs, please contact the site administrators
        </p>
        <button className="error-refresh-btn" type="button" onClick={handleRefresh}>
          Try Again
        </button>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  title: string.isRequired,
  msg: string,
  handleRefresh: func.isRequired,
};

ErrorPage.defaultProps = {
  msg: "",
};

export default ErrorPage;
