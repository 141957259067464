/* eslint-disable no-undef */
import React from "react";
import ReactDOM from "react-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import rootReducer from "./reducers/reducer";

import { msalConfig } from "./authConfig";

import App from "./App";

const reduxDevtools =
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

let store;
if (!reduxDevtools) {
  store = createStore(rootReducer, applyMiddleware(thunk));
} else {
  store = createStore(rootReducer, compose(applyMiddleware(thunk), reduxDevtools));
}

const msalInstance = new PublicClientApplication(msalConfig);
export default msalInstance;

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <Provider store={store}>
    <App msalInstance={msalInstance} />
  </Provider>,
  document.getElementById("root")
);

navigator.serviceWorker?.register("./dummy-worker.js");
