const initialState = {
  files: [],
  filesWaitingToBeUploaded: [],
  filesWaitingToBeDeleted: [],
  backUpFilesWaitingList: [], // In case notification isn't deleted succesfully, restore waiting list from this store
  backUpFilesDeleteWaitingList: [],
  loading: false,
};

// eslint-disable-next-line default-param-last
export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case "FILES_LOADING":
    case "DOWNLOAD_LOADING":
    case "START_FILE_DELETION":
      return {
        ...state,
        loading: true,
      };

    case "ADD_FILE-TO-DATABASE":
      return {
        ...state,
        files: [...state.files, action.payload],
        loading: false,
      };

    case "LOAD_FILES-TO-STATE":
      return {
        ...state,
        files: [...action.payload],
        loading: false,
      };

    case "EMPTY_FILES-FROM-STATE":
      return {
        ...state,
        files: [],
        filesWaitingToBeUploaded: [],
        filesWaitingToBeDeleted: [],
        loading: false,
      };

    case "FILE_UPLOAD_SUCCESS":
    case "DOWNLOAD_SUCCESS":
    case "START_UPLOAD_ERROR":
    case "FILE_UPLOAD_ERROR":
    case "LOAD_FILES_ERROR":
    case "DOWNLOAD_ERROR":
    case "FILE_DELETE_ERROR":
    case "FILE_DELETE-FROM-SHAREPOINT-ERROR":
    case "FILE_DELETE_FROM-DATABASE-ERROR":
      return {
        ...state,
        loading: false,
      };

    case "ADD_FILES-TO-WAITINGLIST":
      return {
        ...state,
        filesWaitingToBeUploaded: [...state.filesWaitingToBeUploaded, ...action.payload],
      };

    case "ADD_FILE-TO-DELETE-WAITINGLIST":
      return {
        ...state,
        filesWaitingToBeDeleted: [...state.filesWaitingToBeDeleted, action.payload],
        files: [...state.files.filter((file) => file.id !== action.payload.fileId)],
      };

    case "REMOVE_FILE-FROM-WAITINGLIST":
      return {
        ...state,
        filesWaitingToBeUploaded: state.filesWaitingToBeUploaded.filter(
          (file) => file.name !== action.payload
        ),
      };

    case "REMOVE_FILE-FROM-DELETE-WAITINGLIST":
      return {
        ...state,
        filesWaitingToBeDeleted: state.filesWaitingToBeDeleted.filter(
          (file) => file.name !== action.payload
        ),
      };

    case "EMPTY_FILES_WAITINGLIST":
      return {
        ...state,
        filesWaitingToBeUploaded: [],
        backUpFilesWaitingList: [...state.filesWaitingToBeUploaded],
        filesWaitingToBeDeleted: [],
        backUpFilesDeleteWaitingList: [...state.filesWaitingToBeDeleted],
      };

    case "RESTORE_FILES_WAITINGLIST":
      return {
        ...state,
        filesWaitingToBeUploaded: [...state.backUpFilesWaitingList],
        backUpFilesWaitingList: [],
        filesWaitingToBeDeleted: [...state.filesWaitingToBeDeleted],
        backUpFilesDeleteWaitingList: [],
      };

    case "FILE_DELETE_SUCCESS":
      return {
        ...state,
        files: state.files.filter((file) => file.name !== action.payload),
        loading: false,
      };

    default:
      return state;
  }
}
