/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { bool } from "prop-types";

import { loadUser } from "../actions/userActions";
import { clearErrors } from "../actions/errorActions";
import logoutUser from "../utils";
import useAccessTokens from "../hooks/useAccessTokens";
import useWindowDimensions from "../hooks/useWindowDimensions";

import logo from "../assets/images/boogie-min.png";
import waveImg from "../assets/images/wave.png";

import "../styles/Header.css";
import "../styles/Navbar.css";

import Toast from "./Toast";
import Navbar from "./Navbar";
import NavMenu from "./NavMenu";
import MenuBtn from "./MenuBtn";

const Header = ({ minimal = false }) => {
  const { width } = useWindowDimensions();
  const [active, setActive] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const isLogged = useSelector((state) => state.user.isLogged);
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const { accounts, instance } = useMsal();
  const { accessToken } = useAccessTokens(accounts || instance.getActiveAccount(), instance);

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (minimal) {
      return;
    }

    if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
    }
    if (isAuthenticated && accessToken && !isLogged && !loading) {
      const { homeAccountId, name, idTokenClaims } = instance.getActiveAccount();

      const userInfo = {
        accountId: homeAccountId,
        username: name,
        roles: idTokenClaims.roles || ["Normal"],
      };

      dispatch(loadUser(userInfo, accessToken));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLogged, accessToken]);

  return (
    <div className={`header-container ${minimal ? "minimal" : "normal"}`}>
      <header className="header">
        <Toast />
        <div className="logocontainer-mobile">
          <h1 className="main-title heading">SAIKKU</h1>
          {width <= 600 && <img alt="" className="logo" src={logo} />}
        </div>
        {width >= 900 ? <img alt="" className="wave img bg-img" src={waveImg} /> : null}
        {width > 600 && (
          <div className="logo-container">
            <img alt="" className="logo" src={logo} />
          </div>
        )}
        {!minimal && isAuthenticated && width <= 600 ? (
          <div className="hamburger-container">
            <MenuBtn active={active} setActive={setActive} />
          </div>
        ) : null}
      </header>
      {!minimal ? (
        width <= 600 ? (
          <NavMenu logoutUser={logoutUser} active={active} setActive={setActive} />
        ) : (
          <Navbar logoutUser={logoutUser} active={active} setActive={setActive} />
        )
      ) : null}
    </div>
  );
};

Header.propTypes = {
  minimal: bool,
};

Header.defaultProps = {
  minimal: false,
};

export default Header;
