export const returnErrors = (msg, status, type, id = null) => ({
  type: "GET_ERRORS",
  payload: {
    msg,
    status,
    id,
    type,
  },
});

export const clearErrors = () => ({
  type: "CLEAR_ERRORS",
});
