/* eslint-disable react/destructuring-assignment */
import { any } from "prop-types";
import React from "react";
import ErrorPage from "../views/ErrorPage";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, msg: null };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    this.setState({
      msg: error.message,
    });
  }

  handleRefresh = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorPage
          title="Something unexpected happened."
          msg={this.state.msg}
          handleRefresh={this.handleRefresh}
        />
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: any.isRequired,
};

export default ErrorBoundary;
